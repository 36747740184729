.foot{
  width: 100%;
  min-width: 1440px;
  height: 100%;
  background: #171717;
  .foot-container{
    width: 1440px;
    height: 353px;
    margin:0 auto;
    position: relative;
    .foot-logo{
      position: absolute;
      top: 74px;
      left: 162px;
      width: 152px;
      height: 50px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  
    .foot-list{
      position: absolute;
      left: 443px;
      top: 75px;
      ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          width: 56px;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 8px;
          cursor: pointer;
          list-style: none;
          color: rgba(255, 255, 255, 0.65);
          // text-decoration:underline;
        }
        li:hover{
          color: #5551FF;
        }
      }
    }
  
    .foot-contact{
      width: 143px;
      position: absolute;
      left: 720px;
      top: 75px;
      &>div:nth-child(1){
        margin-bottom: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        &>span:nth-child(1){
          display: inline-block;
          width: 143px;
          height:20px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.65);
          line-height: 20px;
          margin-bottom: 5px;
        }
        &>span:nth-child(2){
          width: 143px;
          font-size: 21px;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
        }
      }
      &>div:nth-child(2){
        color: rgba(255, 255, 255, 0.65);
        &>span:nth-child(1){
          display: inline-block;
          width: 168px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 4px;
        }
        &>span:nth-child(2){
          display: inline-block;
          width: 203px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  
    .foot-address{
      position: absolute;
      top: 75px;
      right: 255px;
      &>div:nth-child(1){
        width: 187px;
        height: 49px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        line-height: 20px;
        &>span:nth-child(1){
          display: inline-block;
          width: 187px;
          margin-bottom: 5px;
        }
        &>span:nth-child(2){
          display: inline-block;
          color: #ffffff;
          font-size: 21px;
          margin-bottom: 14px;
        }
        &>span:nth-child(3){
          display: inline-block;
          font-size: 14px;
          width: 247px;
          color: rgba(255, 255, 255, 0.65);
          font-weight: 400;
          line-height: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
        &>span:nth-child(4){
          display: inline-block;
          font-size: 14px;
          width: 200px;
          height: 48px;
          color: rgba(255, 255, 255, 0.65);
          font-weight: 400;
          line-height: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
    }
  
    .foot-line{
      width: 1110px;
      height: 1px;
      background: #FFFFFF;
      opacity: 0.19;
      position: absolute;
      left: 164px;
      top: 272px;
    }
  
    .foot-copyright{
      position: absolute;
      left: 165px;
      bottom: 30px;
      &>span:nth-child(1){
        width: 310px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        line-height: 20px;
        margin-right: 10px;
      }
      // &>span:nth-child(2){
      //   width: 310px;
      //   height: 20px;
      //   font-size: 12px;
      //   font-family: PingFangSC-Regular, PingFang SC;
      //   font-weight: 400;
      //   color: rgba(255, 255, 255, 0.65);
      //   line-height: 17px;
      //   margin-right: 10px;
      // }
      &>span:nth-child(2){
        width: 310px;
        height: 20px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        line-height: 17px;
        margin-right: 10px;
        a{
          list-style: none;
          text-decoration: none;
          color: rgba(255, 255, 255, 0.65);
        }
      }
  
    }
  }
}