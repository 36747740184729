.header-container{
  width: 100%;
  height: 90px;
  position: fixed;
  z-index: 9999;
  background: #FFFFFF;
  border-bottom: 1px solid #cccccc;
  .header-wrapper{
    width: 1440px;
    height: 90px;
    margin: 0 auto;
    position: relative;
    .logo{
      position: absolute;
      top: 16px;
      left: 165px;
      width: 152px;
      height: 50px;
      img{
        width: 152px;
        height: 50px;
        cursor: pointer;
      }
    }
    .navigation{
      position: absolute;
      top: 35px;
      right: 164px;
      list-style: none;
      
      &>div{
        float: left;
        width: 68px;
        height: 20px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        line-height: 20px;
        margin-right: 40px;
        cursor: pointer;
        text-decoration: none;
		    outline: none;
      }
      &>div:nth-child(1) {
        width: 32px;
      }
    }
    
  }
}

.header-container-home{
  width: 100%;
  height: 90px;
  position: fixed;
  z-index: 9999;
  .header-wrapper-home{
    width: 1440px;
    height: 90px;
    margin: 0 auto;
    position: relative;
    .logo{
      position: absolute;
      top: 16px;
      left: 165px;
      width: 152px;
      height: 50px;
      img{
        width: 152px;
        height: 50px;
        cursor: pointer;
      }
    }
    .navigation-home{
      position: absolute;
      top: 35px;
      right: 164px;
      list-style: none;
      &>div{
        float: left;
        width: 68px;
        height: 20px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 20px;
        margin-right: 40px;
        cursor: pointer;
        text-decoration: none;
		outline: none;
      }
      &>div:nth-child(1) {
        width: 32px;
      }
    }
  }
}