.App {
  min-height: 1440px;
}
iframe {
  width: 0 !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
